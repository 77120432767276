<template>
  <div v-if="ticket">
    <b-container class="mt-5 mb-5">
      <b-row>
        <b-col lg="4">
          <b-card no-body class="mb-4 h-lg-100 card-scroll">
            <b-card-header>
              <h5 class="fw-light mb-0">Jouw berichten</h5>
            </b-card-header>
            <b-card-body class="pt-0 pb-0">
              <SupportTicketsMenu />
            </b-card-body>
            <b-card-footer>
              <b-button to="/manage/support/new" variant="primary" block>
                Ticket openen
              </b-button>
            </b-card-footer>
          </b-card>

        </b-col>
        <b-col lg="8">

          <b-alert variant="success" show v-if="hasThankyou()">
            <h5>Bedankt</h5>
            Onze medewerkers zullen uw ticket zo spoedig mogelijk in behandeling nemen.
          </b-alert>

          <b-card no-body class="mb-4">
            <b-card-header>
              <h5 class="fw-light mb-0">Vraag: {{ ticket.subject }}</h5>
            </b-card-header>
            <b-card-body>
              <div class="conversation">
                <div v-for="item in messages" :key="item.id" class="mb-4" :class="(item.user != 2 ? 'mr-5 text-left' : 'ml-5 text-right')">
                  <b-alert :variant="(item.user != 2 ? 'info' : 'secondary')" class="mb-0" show>
                    {{ item.message }}
                  </b-alert>
                  <small class="text-muted">{{ item.user != 2 ? 'Verzonden' : 'Beantwoord' }}: {{ item.created_at | formatDate }} om {{ item.created_at | formatTime }}</small>
                </div>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body v-if="!form_sent">
            <b-card-header>
              <h5 class="fw-light mb-0">Reactie versturen</h5>
            </b-card-header>
            <b-card-body>
              <ValidationObserver ref="form1" slim>
                <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                  <div class="mb-3">
                    <!-- <label>Bericht</label> -->
                    <b-form-textarea v-model="form.message" :class="errors.length > 0 ? 'is-invalid' : null" style="min-height:150px;" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </ValidationObserver>
              <b-row>
                <b-col lg="4">
                  <b-button to="/manage/support" variant="secondary" block class="mb-2">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" />  
                    Terug
                  </b-button>
                </b-col>
                <b-col lg="8">
                  <b-button @click="createMessage" variant="primary" block class="mb-2">
                    Versturen
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    import SupportTicketsMenu from '@/components/SupportTicketsMenu'
  
    export default {
        metaInfo: {
            title: 'Support',
        },
        components: {
            SupportTicketsMenu,
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
              ticket: null,
              messages: [],
              form_sent: false,
              form: {
                message: null,
              },
            }
        },
        computed: {},
        methods: {
          getTicket: function() {
            this.$axios.get("https://api.tixgo.nl/tickets/" + this.$route.params.id)
              .then(response => {
                  if (response.data.status == 'success') {
                      this.ticket = response.data.ticket;
                  }
              }
            );
          },
          getMessages: function() {
            this.$axios.get("https://api.tixgo.nl/messages?filter[ticket]=" + this.$route.params.id)
              .then(response => {
                  if (response.data.status == 'success') {
                      this.messages = response.data.messages;
                  }
              }
            );
          },
          createMessage: function() {

            let check = this.$refs['form1'].validate();

            if (!check) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              
              return;
            }

            this.form.ticket = this.ticket.id;

            if (this.form.message) {
              this.$axios.post("https://api.tixgo.nl/messages", this.form)
                .then(response => {
                    if (response.data.status == 'success') {
                      let message = response.data.message;
                      message;
                      this.resetForm();
                      this.getMessages();
                    }
                }
              );
            }
          },
          resetForm: function() {
            this.form = {
              message: null,
            };

            this.form_sent = true;
          },
          hasThankyou: function() {
            return window.location.hash == '#thankyou' ? true : false;
          }
        },
        created() {
          this.getTicket();
          this.getMessages();
        },
        watch: {
          '$route.params.id': function() {
            this.getTicket();
            this.getMessages();
          }
        },
    }
</script>
  
<style scoped>
  .support-item{
    border-bottom:1px solid #eee;
  }
</style>